import React from 'react';
import Header from '../components/Header';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer';

const PublicLayout = () => (
  <div>
    <Header />
    <Outlet /> 
    <Footer />
  </div>
);

export default PublicLayout;
